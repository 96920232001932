'use client'

import { Provider, useStore } from 'react-redux'
import { AppStore, makeStore } from '../../stores'
import { PersistGate } from 'redux-persist/integration/react'
import { useRef } from 'react';

export default function StoreProvider({ children }: { children: React.ReactNode }) {
  const store = makeStore()
//   const store = useStore()
const storeRef = useRef<AppStore | null>(null);
	if (!storeRef.current) {
		storeRef.current = makeStore();
	}

  return (
    
    <Provider store={store}>
      <PersistGate
        loading={<div className="preloader" style={{ opacity: 0.494764 }}></div>}
        // @ts-ignore
        persistor={store.__persistor}
      >
        {children}
      </PersistGate>
    </Provider>
  )
}
