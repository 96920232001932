export const FOOTER_ICON_LIST = [
  {
    name: 'Instagram',
    icon: '/svg/icon_instagram.svg',
    href: 'https://www.instagram.com/likelion.official/',
  },
  {
    name: 'Youtube',
    icon: '/svg/icon_youtube.svg',
    href: 'https://www.youtube.com/channel/UCYaDkwVaOhuoe_LuFr3lWkA',
  },
  {
    name: 'KakaoTalk',
    icon: '/svg/icon_kakao_ch.svg',
    href: 'https://pf.kakao.com/_iyUBM',
  },
  {
    name: 'Brunch',
    icon: '/svg/icon_brunch.svg',
    href: 'https://brunch.co.kr/@likelion',
  },
  {
    name: 'Facebook',
    icon: '/svg/icon_facebook.svg',
    href: 'https://www.facebook.com/likelion.net',
  },
]

export const FOOTER_SERVICE_INFO = [
  {
    name: '이용약관',
    href: 'https://likelion.notion.site/LIKELION-89ba1354b98d4825af14109aebdd3af9',
  },
  {
    name: '개인정보처리방침',
    href: 'https://likelion.notion.site/LIKELION-4d3c7ce22a724b3c99950e853dc7589b',
  },
  {
    name: '환불규정',
    href: 'https://likelion.notion.site/LIKELION-e9139bf291ad4e0b88f8b99f8597d04a',
  },
]

export const FOOTER_COPYRIGHT = [
  '상호명 : 멋쟁이사자처럼',
  '대표 : 나성영',
  'contact@likelion.net',
  '사업자 번호 : 264-88-01106',
  '통신판매업 신고번호 : 2022-서울종로-1534',
]
