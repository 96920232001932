import { useShowroomQuery } from '@/features/showroom/queries/useShowroomQuery'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

export type IUrgentKlass = {
  id: string
  title: string
  image: string | null
  dealine: number | string
  hoursUntilDeadline: number
}

const getUrgentKlassList = () => {
  const today = dayjs()
  const { data: showroomList, isLoading: isShowRoomListLoading } = useShowroomQuery('home', 'kdt')
  const [isLoading, setIsLoading] = useState(true)
  const [urgentKlassList, setUrgentKlassList] = useState<IUrgentKlass[]>([])

  useEffect(() => {
    if (!isShowRoomListLoading && showroomList) {
      const list = showroomList[0].courses
        .map(course => {
          const deadline = dayjs(course.applyEndedAt)
          const hoursUntilDeadline = deadline.diff(today, 'hour')
          const daysUntilDeadline = deadline.diff(today, 'day')

          return {
            id: course.id,
            title: course.title,
            image: course.dashboardImage,
            dealine: daysUntilDeadline === 0 ? 'DAY' : daysUntilDeadline,
            hoursUntilDeadline,
          }
        })
        .filter(course => course.hoursUntilDeadline <= 168 && course.hoursUntilDeadline >= 0)

      setUrgentKlassList(list)
      setIsLoading(false)
    }
  }, [showroomList])

  return {
    urgentKlassList,
    isUrgentKlassListLoaidng: isLoading,
  }
}

export default getUrgentKlassList
