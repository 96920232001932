export const DROPDOWN_LIST = [
  {
    name: '강의목록',
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/courses`,
  },
  {
    name: '수강신청',
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/apply-status`,
  },
  {
    name: '내정보',
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/profile`,
  },
]
