import {
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  UserIcon,
  VideoCameraIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import styles from './Header.module.scss'
import useKDTNavList from '@/features/showroom/hooks/useKDTNavList'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import NebecaTestQuickItem from '../FloatingSidebar/NebecaTestQuickItem'
import UrgentKlassQuickItem from '../FloatingSidebar/UrgentKlassQuickItem'
import getUrgentKlassList from '@/features/klass/hooks/getUrgentKlassList'
import { IProfile } from '@/features/auth/types'
import useMyCurApplication from '@/features/apply/hooks/useMyCurApplication'
import { usePathname } from 'next/navigation'

interface IDropdownMobileProps {
  isSigned: boolean
  profile: IProfile | null
  ondropDownToggleHandler: () => void
  goToSignIn: () => void
}

const DropdownMobile = ({
  isSigned,
  profile,
  ondropDownToggleHandler,
  goToSignIn,
}: IDropdownMobileProps) => {
  const pathname = usePathname()
  const { navList } = useKDTNavList()
  const { urgentKlassList } = getUrgentKlassList()
  const { activeApplicationId, activeApplicationDeadline } = useMyCurApplication()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const toggleSubMenu = (hasSubMenu: boolean) => {
    if (hasSubMenu) setIsSubMenuOpen(prev => !prev)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      ondropDownToggleHandler()
    }

    handleRouteChange()

    return () => {
      setIsSubMenuOpen(false)
    }
  }, [pathname])

  return (
    <div
      className={`fixed left-0 top-0 z-[75] w-full cursor-default overflow-y-auto bg-white px-5 pb-40 ${styles.dropdownMobileWrap}`}
    >
      <header className="sticky top-0 z-50 flex w-full justify-end bg-white py-4">
        <button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            ondropDownToggleHandler()
          }}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </header>
      <main>
        {isSigned && profile && (
          <article className="space-y-4 pb-5">
            <h2 className="text-lg font-semibold">{profile.name} 님</h2>
            {activeApplicationId && (
              <Link
                href={`${process.env.NEXT_PUBLIC_HOME_URL}/school/${activeApplicationId}/apply`}
                className="flex w-full items-center justify-between rounded-2xl border border-neutral-800 px-5 py-4 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.05)]"
              >
                <div className="flex items-center space-x-2.5">
                  <span className="rounded-full bg-red-500 px-2.5 py-1 text-[15px] font-semibold text-white">
                    D-{activeApplicationDeadline}
                  </span>
                  <p className="font-semibold">작성중인 지원서가 있어요!</p>
                </div>
                <ChevronRightIcon className="h-4 w-4 text-neutral-500" />
              </Link>
            )}
            <ul className="grid w-full grid-cols-3 gap-2">
              {POPULAR_MENU_LIST.map(({ title, icon, href }) => (
                <Link key={`자주사용하는_${title}`} href={href} as={href}>
                  <li className="box-border flex w-full flex-col items-center justify-center space-y-1.5 rounded-lg bg-neutral-50 py-4">
                    {icon}
                    <p className="text-sm">{title}</p>
                  </li>
                </Link>
              ))}
            </ul>
          </article>
        )}
        <ul>
          {navList.map(({ title, href, icon, subMenu }, idx) => (
            <li key={`${title}_${idx}`} onClick={() => toggleSubMenu(!!subMenu)}>
              <Link href={href} as={href} className="flex justify-between py-4">
                <span className="font-semibold">{title}</span>
                {subMenu && (
                  <span className={`${isSubMenuOpen ? 'rotate-180' : ''} flex items-center`}>
                    {icon}
                  </span>
                )}
              </Link>
              {subMenu && isSubMenuOpen && (
                <section className="space-y-6 py-3">
                  {subMenu.map(({ title, menuList }, subIdx) => (
                    <div className="space-y-1.5" key={`${title}_${subIdx}`}>
                      <p className="text-[13px] font-medium text-gray-400">{title}</p>
                      <ul className="grid grid-cols-2 gap-1.5 place-self-center">
                        {menuList.map(({ id, title, isApply, isStatic }) => (
                          <li key={`서브메뉴_${title}`}>
                            <Link
                              href={
                                isStatic
                                  ? {
                                      pathname: `${process.env.NEXT_PUBLIC_HOME_URL}/pre-notice`,
                                      query: { preNoticeId: id },
                                    }
                                  : `/school/${id}`
                              }
                              target={isStatic ? '_blank' : '_self'}
                              className="flex items-center gap-1 py-1.5"
                            >
                              <span className="inline-block truncate text-sm">{title}</span>
                              {isApply && (
                                <span className="break-words break-keep rounded bg-gradient-to-r from-yellow-450 to-[#EC64A6] px-1.5 py-0.5 text-[11px] font-semibold text-white">
                                  모집중
                                </span>
                              )}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </section>
              )}
            </li>
          ))}
        </ul>
        <section className="mt-3 grid grid-cols-[90px_90px_90px] gap-2 place-self-start">
          <NebecaTestQuickItem />
          <UrgentKlassQuickItem klassList={urgentKlassList} />
        </section>
      </main>
      <footer className={isSigned ? 'mt-5' : 'mt-6'}>
        {isSigned ? (
          <Link href="/oauth/logout" as="/oauth/logout">
            <span className="text-sm text-neutral-500 underline underline-offset-2">로그아웃</span>
          </Link>
        ) : (
          <button onClick={goToSignIn} className="rounded-full border border-neutral-300 px-4 py-2">
            <span className="text-sm font-medium">로그인</span>
          </button>
        )}
      </footer>
    </div>
  )
}

export default DropdownMobile

const POPULAR_MENU_LIST = [
  {
    title: '강의목록',
    icon: <VideoCameraIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/courses`,
  },
  {
    title: '지원내역',
    icon: <ClipboardDocumentCheckIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/apply-status`,
  },
  {
    title: '내정보',
    icon: <UserIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: `${process.env.NEXT_PUBLIC_HOME_URL}/my/profile`,
  },
]
