import axiosInstance from '@/shared/utils/axios'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'
import { useQuery } from '@tanstack/react-query'
import { IKDT, IKDTResponse } from '../types'

const fetchMyKDTApplication = async () => {
  const resp = await axiosInstance.get<IKDTResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/course/v1/kdt-applies`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IKDT[]
}

export const useMyKDTApplication = () => {
  return useQuery({
    queryKey: ['myKdtApplication'],
    queryFn: () => fetchMyKDTApplication(),
    initialData: [],
  })
}
