import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useShowroomKDTMenuQuery } from '../queries/useShowroomKDTMenuQuery'
import { useMemo } from 'react'

type IStaticSubMenu = {
  [key: string]: {
    id: string
    title: string
    isApply: number
    isStatic: boolean
  }[]
}

const useKDTNavList = () => {
  const { data } = useShowroomKDTMenuQuery()

  const staticSubMenu = useMemo(() => {
    if (data) {
      return {
        ...data,
        심화: [
          ...data['심화'],
          {
            id: 'kdt-aiplusn-1',
            title: 'AI 심화 : 자연어 처리',
            isApply: 0,
            isStatic: true,
          },
          {
            id: 'kdt-aiplusi-1',
            title: 'AI 심화 : 이미지 처리',
            isApply: 0,
            isStatic: true,
          },
        ],
      } as IStaticSubMenu
    }
  }, [data])

  const navList = [
    {
      title: '부트캠프',
      href: '#',
      icon: <ChevronDownIcon className="w-4 shrink-0 group-open:rotate-180" />,
      subMenu:
        staticSubMenu &&
        Object.keys(staticSubMenu)
          .filter(key => staticSubMenu[key] && staticSubMenu[key].length > 0)
          .map(key => ({
            title: key,
            menuList: staticSubMenu[key].map(({ id, title, isApply, isStatic }) => ({
              id,
              title,
              isApply: isApply === 1,
              isStatic,
            })),
          })),
    },
    {
      title: '취업지원',
      href: '/job-support',
    },
    // {
    //   title: '후기&인터뷰',
    //   href: '/voice',
    // },
    // {
    //   title: '포트폴리오',
    //   href: '/portfolio',
    // },
    {
      title: '내일배움카드',
      href: '/nebeca',
    },
  ]

  return {
    navList,
    staticSubMenu,
  }
}

export default useKDTNavList
