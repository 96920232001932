'use client'

import { useClickOutside, useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import QueryString from 'qs'
import { useCallback, useRef, useState } from 'react'
import { useAuth } from 'shared/providers/Auth'

import DropdownGNB from './DropdownGNB'
import GNB from './GNB'
import FloatingSidebar from '../FloatingSidebar'
import { Bars3Icon } from '@heroicons/react/24/outline'
import DropdownMobile from './DropdownMobile'
import MobileFloatingSideBar from '../FloatingSidebar/MobileFloatingSideBar'

const Header = () => {
  const router = useRouter()
  const pathname = usePathname()
  const { signInUrl, isSigned, profile } = useAuth()
  const profileRef = useRef(null)
  const isLaptop = useMediaQuery('(min-width:1024px)')
  const isHiddenPathName = ['card-test']

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(false)

  const dropDownRef = useClickOutside(() => setIsOpenDropdown(false), null, [profileRef.current])

  const state = QueryString.stringify({ state: pathname })
  const signInUrlWithState = `${signInUrl}&${state}`

  const goToSignIn = useCallback(
    () => router.push(signInUrlWithState),
    [router, signInUrlWithState],
  )

  const dropDownToggle = () => {
    if (!isBtnDisable) {
      setIsBtnDisable(true)
      setIsOpenDropdown(prev => !prev)
      setTimeout(() => {
        setIsBtnDisable(false)
      }, 300)
    }
  }

  return (
    <>
      {!isHiddenPathName.includes(pathname.split('/')[1]) &&
        (!pathname.includes('school') && isLaptop ? (
          <FloatingSidebar />
        ) : (
          <MobileFloatingSideBar isDetailPage={pathname.includes('school')} />
        ))}
      <div
        className={`${pathname.includes('nebeca') ? 'static' : 'sticky'} left-0 top-0 z-50 flex h-[54px] w-full items-center border-b border-neutral-200 bg-white lg:h-16`}
      >
        <div className="flex w-full justify-between lg:mx-auto lg:max-w-7xl">
          <section className="flex flex-row items-center gap-2.5">
            <Link href="/">
              <div className="pl-5 lg:pl-4 lg:pr-[15.31px]">
                <img
                  className="h-4 w-[136px] lg:h-[18px] lg:w-[148.69px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/bootcamp_logo.svg'
                  }
                  alt="멋사 부트캠프"
                />
              </div>
            </Link>
            <GNB />
          </section>
          {isLaptop ? (
            <div className="flex items-center justify-center px-6">
              {isSigned ? (
                <div ref={profileRef} className="relative flex items-center justify-center">
                  <button
                    onClick={dropDownToggle}
                    className="relative h-9 w-9 overflow-hidden rounded-full"
                    disabled={isBtnDisable}
                  >
                    {profile && profile.profileImg ? (
                      <Image src={profile.profileImg} alt="회원아이콘" fill={true} sizes="100vw" />
                    ) : (
                      <Image
                        src="/svg/icon_avatar.svg"
                        alt="회원아이콘"
                        fill={true}
                        sizes="100vw"
                      />
                    )}
                  </button>
                  {profile && isOpenDropdown && <DropdownGNB ref={dropDownRef} profile={profile} />}
                </div>
              ) : (
                <button onClick={goToSignIn}>
                  <span className="font-semibold text-neutral-500">로그인</span>
                </button>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  dropDownToggle()
                }}
                disabled={isBtnDisable}
                className="mr-5"
              >
                <Bars3Icon className="h-6 w-6" />
              </button>
              {isOpenDropdown && (
                <DropdownMobile
                  isSigned={isSigned}
                  profile={profile}
                  ondropDownToggleHandler={dropDownToggle}
                  goToSignIn={goToSignIn}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
