import ChannelService from '@/shared/utils/channeltalk'

interface IMobileFloatingSideBarProps {
  isDetailPage?: boolean
}

const MobileFloatingSideBar = ({ isDetailPage }: IMobileFloatingSideBarProps) => {
  const channelService = ChannelService()
  return (
    <button
      className={`${isDetailPage ? 'bottom-20' : 'bottom-4'} fixed right-4 z-30 flex items-center gap-1 rounded-full bg-white bg-gradient-to-r from-[#FF7710] to-[#FF6C6C] px-[18px] py-2.5 shadow-[0_4px_10px_0_rgba(255,108,108,0.5)] lg:bottom-6 lg:right-6 lg:gap-1.5 lg:px-5`}
      onClick={() => channelService?.show()}
    >
      <img src="/svg/icon_white_favicon.svg" alt="부트캠프" className="h-7 w-7 lg:h-10 lg:w-10" />
      <span className="font-semibold text-white lg:text-xl">채팅상담</span>
      <span className="absolute -top-0.5 right-0.5 h-3 w-3 rounded-full border-2 border-white bg-red-450 lg:h-4 lg:w-4"></span>
    </button>
  )
}

export default MobileFloatingSideBar
