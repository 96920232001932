import Link from 'next/link'
import { forwardRef } from 'react'

import { DROPDOWN_LIST } from '@/constatns/header'
import { IProfile } from '@/features/auth/types'

import styles from './Header.module.scss'

interface IDropdonwGNBProps {
  profile: IProfile
}

const DropdownGNB = forwardRef<HTMLDivElement, IDropdonwGNBProps>(({ profile }, ref) => {
  const { name, email } = profile

  return (
    <div ref={ref} className={`${styles.dropdownGNBContainer} z-30`}>
      <header className="p-6">
        <h2 className="text-base font-semibold">{name}님</h2>
        <h3 className="text-sm font-normal text-neutral-400">{email}</h3>
      </header>
      <ul className="flex flex-col border-y border-neutral-200 py-4">
        {DROPDOWN_LIST.map(({ name, href }) => (
          <Link key={`dropdown_${name}`} href={href} as={href} className="px-6 py-3">
            <span className="text-sm">{name}</span>
          </Link>
        ))}
      </ul>
      <footer className="px-5 pb-6 pt-5">
        <Link href="/oauth/logout" as="/oauth/logout">
          <span className="text-sm text-neutral-400">로그아웃</span>
        </Link>
      </footer>
    </div>
  )
})

DropdownGNB.displayName = 'DropdownGNB'
export default DropdownGNB
